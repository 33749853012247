import React, { useContext } from 'react';
import { render as reactRender, unmount as reactUnmount } from 'rc-util/lib/React/render';
import warning from '../_util/warning';
import ConfigProvider, { ConfigContext, globalConfig, warnContext } from '../config-provider';
import ConfirmDialog from './ConfirmDialog';
import destroyFns from './destroyFns';
import { getConfirmLocale } from './locale';
let defaultRootPrefixCls = '';
function getRootPrefixCls() {
    return defaultRootPrefixCls;
}
const ConfirmDialogWrapper = (props) => {
    var _a, _b;
    const { prefixCls: customizePrefixCls, getContainer, direction } = props;
    const runtimeLocale = getConfirmLocale();
    const config = useContext(ConfigContext);
    const rootPrefixCls = getRootPrefixCls() || config.getPrefixCls();
    // because Modal.config set rootPrefixCls, which is different from other components
    const prefixCls = customizePrefixCls || `${rootPrefixCls}-modal`;
    let mergedGetContainer = getContainer;
    if (mergedGetContainer === false) {
        mergedGetContainer = undefined;
        if (process.env.NODE_ENV !== 'production') {
            warning(false, 'Modal', 'Static method not support `getContainer` to be `false` since it do not have context env.');
        }
    }
    return (React.createElement(ConfirmDialog, Object.assign({}, props, { rootPrefixCls: rootPrefixCls, prefixCls: prefixCls, iconPrefixCls: config.iconPrefixCls, theme: config.theme, direction: direction !== null && direction !== void 0 ? direction : config.direction, locale: (_b = (_a = config.locale) === null || _a === void 0 ? void 0 : _a.Modal) !== null && _b !== void 0 ? _b : runtimeLocale, getContainer: mergedGetContainer })));
};
export default function confirm(config) {
    const global = globalConfig();
    if (process.env.NODE_ENV !== 'production' && !global.holderRender) {
        warnContext('Modal');
    }
    const container = document.createDocumentFragment();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    let currentConfig = Object.assign(Object.assign({}, config), { close, open: true });
    let timeoutId;
    function destroy(...args) {
        const triggerCancel = args.some((param) => param && param.triggerCancel);
        if (config.onCancel && triggerCancel) {
            config.onCancel(() => { }, ...args.slice(1));
        }
        for (let i = 0; i < destroyFns.length; i++) {
            const fn = destroyFns[i];
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            if (fn === close) {
                destroyFns.splice(i, 1);
                break;
            }
        }
        reactUnmount(container);
    }
    function render(props) {
        clearTimeout(timeoutId);
        /**
         * https://github.com/ant-design/ant-design/issues/23623
         *
         * Sync render blocks React event. Let's make this async.
         */
        timeoutId = setTimeout(() => {
            const rootPrefixCls = global.getPrefixCls(undefined, getRootPrefixCls());
            const iconPrefixCls = global.getIconPrefixCls();
            const theme = global.getTheme();
            const dom = React.createElement(ConfirmDialogWrapper, Object.assign({}, props));
            reactRender(React.createElement(ConfigProvider, { prefixCls: rootPrefixCls, iconPrefixCls: iconPrefixCls, theme: theme }, global.holderRender ? global.holderRender(dom) : dom), container);
        });
    }
    function close(...args) {
        currentConfig = Object.assign(Object.assign({}, currentConfig), { open: false, afterClose: () => {
                if (typeof config.afterClose === 'function') {
                    config.afterClose();
                }
                // @ts-ignore
                destroy.apply(this, args);
            } });
        // Legacy support
        if (currentConfig.visible) {
            delete currentConfig.visible;
        }
        render(currentConfig);
    }
    function update(configUpdate) {
        if (typeof configUpdate === 'function') {
            currentConfig = configUpdate(currentConfig);
        }
        else {
            currentConfig = Object.assign(Object.assign({}, currentConfig), configUpdate);
        }
        render(currentConfig);
    }
    render(currentConfig);
    destroyFns.push(close);
    return {
        destroy: close,
        update,
    };
}
export function withWarn(props) {
    return Object.assign(Object.assign({}, props), { type: 'warning' });
}
export function withInfo(props) {
    return Object.assign(Object.assign({}, props), { type: 'info' });
}
export function withSuccess(props) {
    return Object.assign(Object.assign({}, props), { type: 'success' });
}
export function withError(props) {
    return Object.assign(Object.assign({}, props), { type: 'error' });
}
export function withConfirm(props) {
    return Object.assign(Object.assign({}, props), { type: 'confirm' });
}
export function modalGlobalConfig({ rootPrefixCls }) {
    warning(false, 'Modal', 'Modal.config is deprecated. Please use ConfigProvider.config instead.');
    defaultRootPrefixCls = rootPrefixCls;
}
